import useIsLoggedIn from '~/client/hooks/user/useIsLoggedIn';
import useSite from '~/client/hooks/useSite';
import getConnections from './helpers/getConnections';
import { useSocialAuthConfQuery } from './SocialAuthConfQuery.generated';

const useSocialAuthConf = () => {
  const { site } = useSite();
  const isLoggedIn = useIsLoggedIn();
  const { loading, error, data } = useSocialAuthConfQuery({
    variables: { site },
    skip: isLoggedIn,
  });
  const auth0Config = data?.userManagement?.auth0Conf;
  const cognitoConfig = data?.userManagement?.cognitoConf;
  const connections = getConnections(
    site,
    auth0Config?.connections || [],
    cognitoConfig?.connections || [],
  );

  return {
    loading,
    error,
    connections,
    auth0Config,
    cognitoConfig,
  };
};

export default useSocialAuthConf;
