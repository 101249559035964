import { createContext } from 'react';

type Props = {
  notifications: NotificationProps[];
  setNotifications: SetState<NotificationProps[]>;
  addNotification: (value: NotificationProps) => void;
  removeNotifications: (ids: string[]) => void;
  containsNotification: (id: string) => boolean;
};

const NotificationsContext = createContext<Props>({
  notifications: [],
  setNotifications: () => null,
  addNotification: ({}) => null,
  removeNotifications: ([]) => null,
  containsNotification: () => false,
});

export default NotificationsContext;
