import useAppContext from '~/shared/context/AppContext/useAppContext';

const useTelegramAPI = () => {
  const { telegramAPI } = useAppContext();

  return {
    telegramAPI,
  };
};

export default useTelegramAPI;
