import useOnAuthRefetchQueries from '~/client/features/SocialAuth/hooks/useOnAuthRefetchQueries';
import useSite from '~/client/hooks/useSite';
import { useTelegramMutation } from '../graphql/TelegramMutation.generated';
import { useTelegramQuery } from '../graphql/TelegramQuery.generated';

const useTelegram = () => {
  const refetchQueries = useOnAuthRefetchQueries();
  const { site, isSportsbet } = useSite();
  const variables = {
    site,
    name: isSportsbet ? 'socialLoginTelegram' : 'TelegramLogin',
  };
  const { loading, error, data } = useTelegramQuery({ variables });
  const [userManagementAuthWithTelegram, { loading: isSaving }] =
    useTelegramMutation({ refetchQueries });

  return { loading, error, data, isSaving, userManagementAuthWithTelegram };
};

export default useTelegram;
