import { useCallback, useEffect, useRef, useState } from 'react';
import type Reaptcha from 'reaptcha';
import useResendingEnableTimeCookie from '~/client/components/2faManaging/hooks/useResendingEnableTimeCookie';
import useRedirectUrl from '~/client/components/Auth/hooks/useRedirectUrl';
import useRedirectToLogin from '~/client/components/Auth/Register/RegisterBody/FormRegister/useFormRegister/useRedirectToLogin';
import hasNewDeviceError from '~/client/components/Auth/Shared/utils/hasNewDeviceError';
import hasSms2faError from '~/client/components/Auth/Shared/utils/hasSms2faError';
import setDataToGib from '~/client/components/Auth/Shared/utils/setDataToGib';
import socialLoginErrors from '~/client/components/Auth/Shared/utils/socialLoginErrors';
import useFunModeToggle from '~/client/components/Game/GameView/GameViewDesktop/GameBlock/GameFooter/FunModeToggle/useFunModeToggle';
import updateAndOpenChat from '~/client/features/Comms/utils/updateAndOpenChat';
import useProto from '~/client/features/Proto/hooks/useProto';
import useConnectionClicked from '~/client/features/SocialAuth/SocialAuthLogin/SocialAuthLoginView/ConnectionButton/hooks/useConnectionClicked';
import useSocialAuthType from '~/client/hooks/cookies/useSocialAuthType';
import useForm from '~/client/hooks/form/useForm';
import useTranslation from '~/client/hooks/i18n/useTranslation';
import useRouter from '~/client/hooks/router/useRouter';
import usePhoneNumberHint from '~/client/hooks/usePhoneNumberHint';
import useLoginError from '~/client/hooks/user/useLoginError';
import useToastContext from '~/client/layout/DefaultLayout/LayoutContext/Toast/useToastContext';
import useAccountPopoverStore from '~/client/store/useAccountPopoverStore';
import useAppContext from '~/shared/context/AppContext/useAppContext';
import Cookie from '~/shared/utils/Cookie';
import deserializeErrors from '~/shared/utils/errors/deserializeErrors';
import nativeReplace from '~/shared/utils/router/nativeReplace';
import type FormValues from './FormValues';
import useNewDeviceNotification from './useNewDeviceNotification';
import useSms2fa from './useSms2fa';
import useHandleLogin from '../../../../hooks/useHandleLogin';

type Props = {
  isPopover?: boolean;
  onSuccess?: () => void;
  defaultValues?: {
    username?: string;
  };
};

const useFormLogin = ({ onSuccess, isPopover, defaultValues }: Props) => {
  const { isProtoEnabled } = useProto();
  const { reload, locale, push } = useRouter();
  const {
    captchaConfig: { isLoginCaptchaRequired },
  } = useAppContext();
  const { showNewDeviceNotification } = useNewDeviceNotification();
  const [loginSuccessful, setLoginSuccessful] = useState(false);
  const [captchaRequired, setCaptchaRequired] = useState(
    isLoginCaptchaRequired,
  );
  const redirect = useRedirectUrl();
  const { dismissPopover } = useAccountPopoverStore();
  const { handleLogin, loading } = useHandleLogin({ captchaRequired });
  const { error: loginError, setError, resetError } = useLoginError();
  const {
    formState,
    control,
    handleSubmit,
    errors,
    setErrors,
    clearErrors,
    setValue,
    getValues,
    register,
    required,
    watch,
  } = useForm<FormValues>({
    defaultValues: { uses2FA: false, username: defaultValues?.username },
  });
  const { setPhoneNumberHint, phoneHint } = usePhoneNumberHint();
  const { showToast } = useToastContext();
  const [initialSocialAuthType] = useSocialAuthType();
  const [socialAuthType, setSocialAuthType] = useState(initialSocialAuthType);
  const [needsSms2fa, setNeedsSms2fa] = useState(false);
  const { setResendingEnableTimeCookie } = useResendingEnableTimeCookie();
  const handleConnectionClick = useConnectionClicked();
  const { showRedirectToLoginNotification } = useRedirectToLogin();
  const { handleChange } = useFunModeToggle();

  const recaptchaRef = useRef<Reaptcha>(null);

  const resetNeedsSms2fa = useCallback(() => {
    setResendingEnableTimeCookie();
    setNeedsSms2fa(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (initialSocialAuthType) {
      setSocialAuthType(initialSocialAuthType);
    }
  }, [initialSocialAuthType]);
  useEffect(() => {
    if (socialAuthType && loginError.includes('SMS_2FA_')) {
      resetNeedsSms2fa();
    }
  }, [socialAuthType, loginError, resetNeedsSms2fa]);
  useEffect(() => {
    if (Cookie.get('existingEmail')) {
      showRedirectToLoginNotification();
      Cookie.remove('existingEmail');
    }

    if (loginError && !socialLoginErrors.includes(loginError)) {
      const error = {
        message: loginError,
      };
      setErrors([error] as GenericErrorType[]);
    }
    if (loginSuccessful) {
      if (isPopover) {
        dismissPopover(true);
        reload();
      } else {
        nativeReplace(redirect);
      }
    }
  }, [
    isPopover,
    handleChange,
    dismissPopover,
    locale,
    loginSuccessful,
    push,
    redirect,
    reload,
    loginError,
    setErrors,
    showRedirectToLoginNotification,
  ]);

  const uses2FA = watch('uses2FA');
  const { isValid } = formState;
  const isSubmitDisabled = !isValid;

  const authenticatorLabelCheckbox = useTranslation(
    'profile.google_authenticator_checkbox',
  );
  const authenticatorLabelCode = useTranslation(
    'profile.google_authenticator_code',
  );

  const submit = async (values: FormValues) => {
    clearErrors();
    resetError();
    setSocialAuthType('');

    const payload = await handleLogin(values);
    if (payload.errors?.length) {
      setErrors(payload.errors as GenericErrorType[]);
      setPhoneNumberHint(payload.errors);
      const deserializedErrors = deserializeErrors(
        payload.errors,
      ) as DeserializeError;
      if (hasNewDeviceError(deserializedErrors)) {
        showNewDeviceNotification();
      }
      const isOtp = !!deserializedErrors?.otp;
      const hasSms2fa = hasSms2faError(deserializedErrors);
      if (hasSms2fa) {
        setResendingEnableTimeCookie();
        setNeedsSms2fa(true);
      }
      if (!isOtp) {
        setNeedsSms2fa(false);
      }
      recaptchaRef?.current?.reset();
      if (deserializedErrors?.captcha) {
        setCaptchaRequired(true);
        setNeedsSms2fa(false);
      }
      setValue('uses2FA', uses2FA || (isOtp && !hasSms2fa && !needsSms2fa));
      return;
    }

    const userData = payload.userManagement;

    if (isProtoEnabled) {
      window.proto?.reset();
    } else {
      updateAndOpenChat({
        opts: {
          customer: {
            externalId: userData?.currentProfile?._id,
            name: userData?.currentUser?.username,
            email: userData?.currentUser?.email,
          },
        },
      });
    }

    setDataToGib(userData?.currentProfile?._id || '', payload.sessionId || '');

    onSuccess?.();
    setLoginSuccessful(true);
  };

  const formValues = getValues();

  const { authCodeErrorMessage } = useSms2fa(errors, submit, formValues);

  const handleCodeSubmit = (otp: string) => {
    if (loading || loginSuccessful) {
      return;
    }

    if (socialAuthType) {
      resetError();
      handleConnectionClick({
        handle: socialAuthType as Connection,
        otp,
        setOtpError,
      });
    } else {
      submit({ ...formValues, otp, uses2FA: true });
    }
  };
  const setOtpError = () => {
    setErrors([{ message: 'error.SMS_2FA_IS_REQUIRED' }] as GenericErrorType[]);
    resetNeedsSms2fa();
  };

  const handleBlur = () => {
    clearErrors();
  };

  return {
    control,
    handleBlur,
    errors,
    uses2FA,
    loading: loading || loginSuccessful,
    isSubmitDisabled,
    clearErrors,
    register,
    required,
    captchaRequired,
    recaptchaRef,
    authenticatorLabelCheckbox,
    authenticatorLabelCode,
    phoneHint,
    needsSms2fa,
    handleDialogClose: () => {
      clearErrors();
      resetError();
      setNeedsSms2fa(false);
      setSocialAuthType('');
    },
    handleCodeSubmit,
    authCodeErrorMessage,
    handleSubmit: handleSubmit(
      async (values) => {
        submit(values);
      },
      (values) => {
        if (values.otp?.message === 'error.SMS_2FA_IS_REQUIRED') {
          setResendingEnableTimeCookie();
          setNeedsSms2fa(true);
        }
      },
    ),
    handleResendCodeClick: () => {
      handleCodeSubmit('');
      showToast({
        message: 'profile.new_code_sent',
        timeout: 6000,
        variant: 'success',
        position: 'bottom',
      });
      setError('SMS_2FA_IS_REQUIRED');
    },
  };
};

export default useFormLogin;
