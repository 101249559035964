import Translate from '~/client/shared/i18n/Translate';
import useNotificationsContext from '~/client/shared/NotificationContext/useNotificationsContext';

const useNewDeviceNotification = () => {
  const { addNotification } = useNotificationsContext();

  const showNewDeviceNotification = async () => {
    addNotification({
      type: 'general',
      customId: 'NEW_DEVICE_DETECTED',
      sticky: true,
      dismissable: true,
      timeout: 2000,
      content: () => (
        <div>
          <Translate id="new_device.email_sent_notification" />
        </div>
      ),
    });
  };

  return { showNewDeviceNotification };
};

export default useNewDeviceNotification;
