import type { FetchResult } from '@apollo/client';
import { useRouter } from 'next/navigation';
import useIsStepByStepEnabled from '~/client/components/Auth/Authenticate/AuthSteps/hooks/useIsStepByStepEnabled';
import useRedirectUrl from '~/client/components/Auth/hooks/useRedirectUrl';
import type { ErrorsFragment } from '~/client/components/Auth/Login/useLogin/LoginMutation.generated';
import setDataToGib from '~/client/components/Auth/Shared/utils/setDataToGib';
import useCid from '~/client/hooks/cookies/marketing/useCid';
import useRefAff from '~/client/hooks/cookies/marketing/useRefAff';
import useRefCode from '~/client/hooks/cookies/marketing/useRefCode';
import useFriendRef from '~/client/hooks/cookies/useFriendRef';
import usePhoneNumberHint from '~/client/hooks/usePhoneNumberHint';
import useLoginError from '~/client/hooks/user/useLoginError';
import useSite from '~/client/hooks/useSite';
import type { UserManagementError } from '~/schema/public';
import useAppContext from '~/shared/context/AppContext/useAppContext';
import useToken from '~/shared/hooks/useToken';
import authType from '~/shared/utils/auth/authType';
import serialize from '~/shared/utils/serialize';
import SessionStorage from '~/shared/utils/storage/SessionStorage';
import useTelegram from './useTelegram';
import type DataAuthType from '../@types/DataAuthType';
import type { TelegramMutation } from '../graphql/TelegramMutation.generated';

type Args = {
  dataAuth: DataAuthType;
  code?: string;
  otp?: string;
  setOtpError?: () => void;
};

const useTelegramAuth = (onSuccess?: () => void) => {
  const { site } = useSite();
  const appContext = useAppContext();
  const refCode = useRefCode();
  const [refAff] = useRefAff();
  const [friendRef] = useFriendRef();
  const [cid] = useCid();
  const redirect = useRedirectUrl();
  const [, setToken] = useToken();
  const router = useRouter();
  const { setError } = useLoginError();
  const { userManagementAuthWithTelegram } = useTelegram();
  const { setPhoneNumberHint } = usePhoneNumberHint();
  const isStepByStepEnabled = useIsStepByStepEnabled();

  const handleTelegramAuth = async ({ dataAuth, otp, setOtpError }: Args) => {
    const processResult = (
      result: FetchResult<TelegramMutation>,
      telegramData: string,
    ) => {
      const encodedRedirectUrl = encodeURIComponent(redirect as string);
      const {
        token = '',
        errors,
        sessionId,
        userManagement,
      } = result?.data?.userManagementAuthWithTelegram || {};
      const criticalErrors = getCriticalErrors(errors);
      const hasOtpError = getOtpError(criticalErrors);

      if (!errors) {
        // eslint-disable-next-line no-console
        console.log(`Setting token from Telegram`);
        setToken(token);

        if (userManagement?.profile?._id && sessionId) {
          setDataToGib(userManagement?.profile?._id, sessionId);
        }

        if (onSuccess) {
          onSuccess();
          return router.refresh();
        }
        const redirectPath =
          redirect.includes('login') || redirect.includes('register');
        const defaultRedirect =
          redirectPath || !redirect.length ? '/' : redirect;
        router.push(defaultRedirect, undefined);
        return router.refresh();
      }
      if (criticalErrors && hasOtpError) {
        setOtpError?.();
        if (isStepByStepEnabled) {
          router.push(`/authenticate?redirect=${encodedRedirectUrl}`);
        }
        router.push(
          `/two-factor-authentication?redirect=${encodedRedirectUrl}`,
          undefined,
        );
        return { loginError: true };
      }
      if (criticalErrors && !hasOtpError) {
        // Logging is useful to see your user ID when account is disabled etc
        // eslint-disable-next-line no-console
        console.log('Telegram login errors', JSON.stringify(errors));
        setError(criticalErrors);
        setPhoneNumberHint(errors);
        router.push(`/login?redirect=${encodedRedirectUrl}`, undefined);
        return { loginError: true };
      }

      const missingFields = errors
        .filter((el: UserManagementError) => el.message === 'IS_REQUIRED')
        .map((el: UserManagementError) => el.params && el.params[0].value);
      SessionStorage.setItem(
        'social_auth_missing_fields',
        missingFields.join(','),
      );
      authType.set('telegram');
      SessionStorage.setItem('social_auth_token', telegramData);
      if (isStepByStepEnabled) {
        router.push(`/authenticate?redirect=${encodedRedirectUrl}`);
      }
      router.push(`/complete-registration?redirect=${encodedRedirectUrl}`);

      return null;
    };
    const getCriticalErrors = (errors?: ErrorsFragment[]) =>
      errors
        ?.filter((el) => el.message !== 'IS_REQUIRED')
        .map((el) => el.message)
        .join(',') ?? '';
    const getOtpError = (criticalErrors: string) => {
      if (criticalErrors.includes('OTP') || criticalErrors === 'INVALID') {
        return true;
      }
      return false;
    };

    const domain = global.location?.hostname;
    const language = appContext.language;
    const telegramData = serialize(dataAuth);
    if (!telegramData) {
      return;
    }

    const result = await userManagementAuthWithTelegram({
      variables: {
        telegramData,
        domain,
        language,
        refCode,
        refAff,
        cid,
        site,
        otp,
        friendRef,
      },
    });

    processResult(result, telegramData);
  };

  return { handleTelegramAuth };
};

export default useTelegramAuth;
