import type { DeepMap, DeepPartial, FieldError } from 'react-hook-form';
import useTranslation from '~/client/hooks/i18n/useTranslation';
import useLoginError from '~/client/hooks/user/useLoginError';
import useToastContext from '~/client/layout/DefaultLayout/LayoutContext/Toast/useToastContext';
import type FormValues from '../FormValues';

const useSms2fa = (
  errors: DeepMap<DeepPartial<FormValues>, FieldError>,
  submit: (values: FormValues) => void,
  formValues: FormValues,
) => {
  const { showToast } = useToastContext();
  const { error: loginError } = useLoginError();

  const loginErrorMessage =
    loginError && loginError !== 'SMS_2FA_IS_REQUIRED'
      ? `error.${loginError}`
      : '';

  const errorMessage =
    errors?.otp?.message && errors?.otp?.message !== 'error.SMS_2FA_IS_REQUIRED'
      ? errors.otp.message
      : '';

  const authCodeErrorMessage = useTranslation(
    errorMessage || loginErrorMessage,
  );

  const handleResendCodeClick = () => {
    submit(formValues);
    showToast({
      message: 'profile.new_code_sent',
      timeout: 6000,
      variant: 'success',
      position: 'bottom',
    });
  };

  return { authCodeErrorMessage, handleResendCodeClick };
};

export default useSms2fa;
