import useLogin from '~/client/components/Auth/Login/useLogin';
import useUpdateGtmUserData from '~/client/components/Auth/Shared/utils/useUpdateGtmUserData';
import startFraudEngine from '~/client/shared/fraud/fraudEngine';
import useToken from '~/shared/hooks/useToken';
import sendCustomSymplifyValue from '~/shared/symplify/sendCustomSymplifyValue';
import trackEvent from '~/shared/trackEvent';
import type FormValues from '../../Login/LoginBody/FormLogin/useFormLogin/FormValues';

type Args = {
  captchaRequired: boolean;
};

const useHandleLogin = ({ captchaRequired }: Args) => {
  const [, setToken] = useToken();
  const { handleLogin, loading } = useLogin({ captchaRequired });
  const { updateGtmUserData } = useUpdateGtmUserData();
  const doLogin = async (values: FormValues) => {
    const { data } = await handleLogin(values);
    const {
      token = '',
      deviceDataToken = '',
      errors,
      userManagement,
      sessionId,
    } = data?.userManagementLoginViaPassword ?? {};

    if (errors) {
      return { errors };
    }

    setToken(token);
    startFraudEngine({ deviceDataToken });
    await updateGtmUserData({ authToken: token });
    trackEvent({ loginType: 'direct' }, 'loginSuccess');
    sendCustomSymplifyValue(userManagement?.currentProfile?._id);

    return { userManagement, sessionId };
  };
  return { handleLogin: doLogin, loading };
};

export default useHandleLogin;
