import useCookie from '~/shared/hooks/useCookie';

const useOtpValue = () => {
  const [otpValue, set, clear] = useCookie<string>('oauth_otp');
  const inThirtySeconds = () => new Date(Date.now() + 30000);
  const setOtpValue = (value: string) =>
    set(value, { expires: inThirtySeconds() });

  return [otpValue, setOtpValue, clear] as const;
};

export default useOtpValue;
