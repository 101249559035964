import { useContext } from 'react';
import NotificationsContext from './NotificationsContext';

const useNotificationsContext = () => {
  const context = useContext(NotificationsContext);
  if (context === undefined) {
    throw new Error(
      'useNotificationsContext should be used within the NotidicationsContext',
    );
  }

  return context;
};

export default useNotificationsContext;
