import { useCallback } from 'react';
import useRedirectUrl from '~/client/components/Auth/hooks/useRedirectUrl';
import useSocialAuthType from '~/client/hooks/cookies/useSocialAuthType';
import useFeature from '~/client/hooks/feature/useFeature';
import useRouter from '~/client/hooks/router/useRouter';
import useAppContext from '~/shared/context/AppContext/useAppContext';
import getEnv from '~/shared/utils/getEnv';
import getCsrfToken from '../../csrf/getCsrfToken';
import useSocialAuthConf from '../useSocialAuthConf';

const useCognitoCallbackUri = () => {
  const { pathPrefix } = useAppContext();
  return `${global.location?.origin}${pathPrefix}/cognito-callback`;
};

const COGNITO_CONNECTION_MAP: Partial<Record<Connection, string>> = {
  facebook: 'Facebook',
  'google-oauth2': 'Google',
  apple: 'SignInWithApple',
};

const useCognitoConnection = () => {
  const { cognitoConfig } = useSocialAuthConf();
  const redirectURL = useCognitoCallbackUri();
  const { pathname, asPath } = useRouter();
  const isAuth88CallbackEnabled = useFeature('isAuth88CallbackEnabled');
  const cognitoRedirectUri = isAuth88CallbackEnabled
    ? getEnv('SOCIAL_AUTH_CALLBACK_URL') ?? 'https://auth88.io/auth-callback'
    : redirectURL;

  const redirectPath = useRedirectUrl();
  const redirect = redirectPath === '/' ? asPath : redirectPath;
  const [, setSocialAuthType] = useSocialAuthType();
  const handleClick = useCallback(
    (connection: Connection) => {
      if (!cognitoConfig) {
        return;
      }
      const cognitoDomain = cognitoConfig.domain;
      const cognitoClientId = cognitoConfig.clientId; // dynamic from cognitoConf
      const cognitoConnection = COGNITO_CONNECTION_MAP[connection];
      try {
        const state = btoa(
          JSON.stringify({
            connection,
            redirect,
            csrf: getCsrfToken(),
            redirectURL,
          }),
        );
        if (!state) {
          console.error('Cognito state error', {
            state: {
              connection,
              redirect,
              csrf: getCsrfToken(),
              redirectURL,
            },
          });
        }
        sessionStorage.setItem('social_auth_path', pathname);
        setSocialAuthType(connection);

        const cognitoAuthUri = `https://${cognitoDomain}/oauth2/authorize?identity_provider=${cognitoConnection}&client_id=${cognitoClientId}&state=${state}&response_type=token&redirect_uri=${cognitoRedirectUri}`;
        location.assign(cognitoAuthUri);
      } catch (error) {
        console.error('Cognito uri error', {
          error,
          state: {
            connection,
            redirect,
            csrf: getCsrfToken(),
            redirectURL,
          },
        });
      }
    },
    [
      cognitoConfig,
      cognitoRedirectUri,
      redirect,
      redirectURL,
      setSocialAuthType,
      pathname,
    ],
  );
  return handleClick;
};

export default useCognitoConnection;
