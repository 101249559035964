import { FormItem, Button } from '@heathmont/moon-components';
import Translate from '~/client/shared/i18n/Translate';

type Props = { loading: boolean; disabled: boolean; handleSubmit: () => void };

const SubmitLogin: FC<Props> = ({ loading, disabled, handleSubmit }) => (
  <FormItem>
    <Button
      variant="primary"
      fullWidth
      type="submit"
      onClick={handleSubmit}
      progress={loading}
      disabled={disabled}
    >
      <Translate id="profile.login" />
    </Button>
  </FormItem>
);

export default SubmitLogin;
