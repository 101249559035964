const METAMASK_CONNECTION: SocialConnection = {
  handle: 'metamask',
  title: 'Metamask',
};

const TELEGRAM_CONNECTION: SocialConnection = {
  handle: 'telegram',
  title: 'Telegram',
};

const TONCONNECT_CONNECTION: SocialConnection = {
  handle: 'ton',
  title: 'Ton Keeper',
};

const APPLE_CONNECTION: SocialConnection = { handle: 'apple', title: 'Apple' };

const getConnections = (
  site: Site,
  auth0Connections: SocialConnection[],
  cognitoConnections: SocialConnection[],
): SocialConnection[] => {
  const combinedConnections = [
    ...cognitoConnections,
    ...auth0Connections,
    METAMASK_CONNECTION,
    TONCONNECT_CONNECTION,
  ];
  const connections = combinedConnections.map((el) => ({
    handle: el.handle,
    title: el.title,
  }));
  return parseConnections(site, connections);
};

const parseConnections = (
  site: Site,
  connections: SocialConnection[],
): SocialConnection[] => {
  const connectionMap: { [key: string]: SocialConnection[] } = {
    bitcasino: [...connections, TELEGRAM_CONNECTION, APPLE_CONNECTION],
    sportsbet: [...connections, TELEGRAM_CONNECTION],
    default: [...connections],
  };
  return deduplicateConnections(connectionMap[site] || connectionMap.default);
};

const deduplicateConnections = (connections: SocialConnection[]) =>
  connections.reduce((result: SocialConnection[], el) => {
    const isUnique = !result.some((el2) => el2.title === el.title);
    return isUnique ? [...result, el] : result;
  }, []);

export default getConnections;
