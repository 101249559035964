import { Button, Text } from '@heathmont/moon-components';
import styled from 'styled-components';
import useResendCodeTimer from '~/client/components/2faManaging/hooks/useResendCodeTimer';
import Translate from '~/client/shared/i18n/Translate';

type Props = {
  onResendCodeClick: () => void;
};

const UnderlinedText = styled(Text)`
  text-decoration: underline;
`;

const ResendCodeButton: FC<Props> = ({ onResendCodeClick }) => {
  const { handleResendClick, timeLeftFormatted, isTimeOver } =
    useResendCodeTimer(onResendCodeClick);

  if (isTimeOver) {
    return (
      <Button onClick={(event) => handleResendClick(event)} size="small">
        <UnderlinedText size={14}>
          <Translate id="profile.send_code_again" />
        </UnderlinedText>
      </Button>
    );
  }
  return (
    <Text size={14} color="trunks.100">
      <Translate id="profile.resend_in" /> {timeLeftFormatted}
    </Text>
  );
};

export default ResendCodeButton;
