import { useCallback } from 'react';
import useSocialAuthType from '~/client/hooks/cookies/useSocialAuthType';
import useSite from '~/client/hooks/useSite';
import useTelegramAPI from './useTelegramAPI';
import useTelegramAuth from './useTelegramAuth';
import type DataAuthType from '../@types/DataAuthType';
import { useTelegramQuery } from '../graphql/TelegramQuery.generated';

type Args = { code?: string; otp?: string; setOtpError?: () => void };

const useTelegramConnection = () => {
  const { site, isSportsbet, siteConfig } = useSite();
  const { telegramAPI } = useTelegramAPI();
  const variables = {
    site,
    name: isSportsbet ? 'socialLoginTelegram' : 'TelegramLogin',
  };
  const [, setSocialAuthType] = useSocialAuthType();
  const { loading, error, data } = useTelegramQuery({ variables });
  const { handleTelegramAuth } = useTelegramAuth();

  const handleTelegramConnect = useCallback(
    ({ setOtpError, otp }: Args) => {
      const domain = global.location.host;
      const botId = telegramAPI?.[domain] || telegramAPI?.[siteConfig.domain];

      if (!botId) {
        return;
      }

      setSocialAuthType('telegram');
      global?.Telegram?.Login.auth(
        {
          bot_id: botId,
          request_access: true,
        },
        (dataAuth: DataAuthType) => {
          handleTelegramAuth({ dataAuth, setOtpError, otp });
        },
      );
    },
    [telegramAPI, siteConfig.domain, setSocialAuthType, handleTelegramAuth],
  );

  return { loading, error, data, handleTelegramConnect };
};

export default useTelegramConnection;
