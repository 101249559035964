import { Dialog } from '@heathmont/moon-components';
import rem from 'polished/lib/helpers/rem';

type Props = {
  onClose: () => void;
  header: JSX.Element;
};

const DesktopDialog: FC<Props> = ({ onClose, children, header }) => (
  <Dialog
    maxWidth={rem(448)}
    hideCloseButton
    isOpen
    onDismiss={onClose}
    variant="new"
    heading={header}
    position="CENTER"
  >
    {children}
  </Dialog>
);

export default DesktopDialog;
