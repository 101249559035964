import { useCallback } from 'react';
import useCognitoConnection from '~/client/features/SocialAuth/hooks/useCognitoConnection';
import useOtpValue from '~/client/hooks/cookies/useOtpValue';
import useAuth0ConnectionClicked from './useAuth0ConnectionClicked';
import useSocialAuthConf from '../../../../hooks/useSocialAuthConf';
import useTelegramConnection from '../../../Telegram/hooks/useTelegramConnection';

type Args = { otp?: string; handle: Connection; setOtpError?: () => void };

const useConnectionClicked = () => {
  const { cognitoConfig } = useSocialAuthConf();
  const cognitoConnectionClicked = useCognitoConnection();
  const auth0ConnectionClicked = useAuth0ConnectionClicked();
  const { handleTelegramConnect } = useTelegramConnection();

  const [, setOtpValue] = useOtpValue();

  const handleClick = useCallback(
    ({ handle, otp, setOtpError }: Args) => {
      if (otp?.length) {
        setOtpValue(otp);
      }
      const isCognitoConnection = cognitoConfig?.connections?.some(
        (el) => el.handle === handle,
      );

      const isTelegramConnection = handle === ('telegram' as Connection);

      if (isTelegramConnection) {
        return handleTelegramConnect({ setOtpError, otp });
      }
      if (isCognitoConnection) {
        cognitoConnectionClicked(handle);
      } else {
        auth0ConnectionClicked(handle);
      }
      return null;
    },
    [
      auth0ConnectionClicked,
      cognitoConfig?.connections,
      cognitoConnectionClicked,
      handleTelegramConnect,
      setOtpValue,
    ],
  );
  return handleClick;
};

export default useConnectionClicked;
