import useTranslation from '~/client/hooks/i18n/useTranslation';
import CustomNotificationEvent from '@/client/events/CustomNotificationEvent';

const useRedirectToLogin = () => {
  const waitRedirect = useTranslation('login.wait_redirect');

  const showRedirectToLoginNotification = async () => {
    CustomNotificationEvent.create({
      type: 'normal',
      data: {
        body: waitRedirect,
      },
      priority: 'normal',
    });
  };

  return { showRedirectToLoginNotification };
};

export default useRedirectToLogin;
