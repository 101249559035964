import { DialogContent } from '@heathmont/moon-components';
import { rem } from '@heathmont/moon-utils';
import styled from 'styled-components';

const MobileDialogContent = styled(DialogContent)`
  background-color: ${({ theme }) => theme.color.gohan[100]};
  max-width: unset;
  width: 100%;
  top: auto;
  bottom: 0;
  transform: none;
  position: fixed;
  border-radius: ${rem(12)} ${rem(12)} 0 0;
  padding: 0;
  overflow-y: scroll;
`;

export default MobileDialogContent;
