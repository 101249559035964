import { Text } from '@heathmont/moon-components';
import { AuthCode } from '@heathmont/moon-core';
import Dialog from '~/client/components/2faManaging/Dialog';
import FormCheckbox from '~/client/shared/form/FormCheckbox';
import FormError from '~/client/shared/form/FormError';
import FormInput from '~/client/shared/form/FormInput';
import FormReCAPTCHA from '~/client/shared/form/FormReCAPTCHA';
import Translate from '~/client/shared/i18n/Translate';
import Stack from '~/client/shared/Stack';
import FlexStack from '~/client/shared/styles/FlexStack';
import Cookie from '~/shared/utils/Cookie';
import ResendCodeButton from './ResendCodeButton';
import useFormLogin from './useFormLogin';
import SubmitLogin from '../../../Shared/SubmitLogin';

type Props = {
  isPopover?: boolean;
  onSuccess?: () => void;
};

const FormLogin: FC<Props> = ({ onSuccess, isPopover = false }) => {
  const prefilledEmail = Cookie.get('existingEmail');
  const {
    uses2FA,
    errors,
    loading,
    control,
    handleSubmit,
    isSubmitDisabled,
    captchaRequired,
    required,
    recaptchaRef,
    authenticatorLabelCheckbox,
    authenticatorLabelCode,
    phoneHint,
    needsSms2fa,
    handleDialogClose,
    handleCodeSubmit,
    authCodeErrorMessage,
    handleResendCodeClick,
    clearErrors,
    handleBlur,
  } = useFormLogin({
    isPopover,
    onSuccess,
    defaultValues: { username: prefilledEmail },
  });

  return (
    <form>
      <Stack>
        <FormInput
          {...required('username')}
          type="text"
          onBlur={handleBlur}
          label="profile.email_username"
          autoCapitalize="off"
          error={errors.email?.message ?? errors.username?.message}
        />
        <FormInput
          {...required('password')}
          onBlur={handleBlur}
          type="password"
          label="profile.password"
          error={errors.password?.message}
        />
        <FormCheckbox
          {...required('uses2FA', false)}
          label={authenticatorLabelCheckbox}
        />
        {uses2FA && (
          <FormInput
            type="tel"
            label={authenticatorLabelCode}
            autoComplete="off"
            error={errors.otp?.message}
            {...required('otp', uses2FA)}
          />
        )}
        {captchaRequired && (
          <FormReCAPTCHA
            recaptchaRef={recaptchaRef}
            name="captcha"
            control={control}
            error={errors.captcha?.message}
            rules={{
              required: captchaRequired && 'errors.input.required',
            }}
          />
        )}
        <SubmitLogin
          loading={loading}
          handleSubmit={handleSubmit}
          disabled={isSubmitDisabled}
        />
        {errors?.global?.message && (
          <FormError
            message={errors.global.message}
            data-testid={errors?.global?.message}
          />
        )}
        {needsSms2fa && (
          <Dialog
            onClose={handleDialogClose}
            title="profile.verify_phone_number"
          >
            <Text size={14} color="trunks.100">
              <Translate
                id="profile.enter_code_from_sms"
                values={{ phone: phoneHint }}
              />
            </Text>
            <AuthCode
              onlyDigits
              onSubmit={handleCodeSubmit}
              onChange={() => clearErrors()}
              errorMessage={authCodeErrorMessage}
              data-testid="sms-code-field"
            />
            <FlexStack inline gridGap="4px" alignItems="center">
              <Text size={14} color="trunks.100">
                <Translate id="profile.did_not_get_code" />
              </Text>
              <ResendCodeButton onResendCodeClick={handleResendCodeClick} />
            </FlexStack>
          </Dialog>
        )}
      </Stack>
    </form>
  );
};

export default FormLogin;
