import useCookie from '~/shared/hooks/useCookie';

const delay = 30 * 1000;

const useResendingEnableTimeCookie = () => {
  const [resendingEnableTimeCookie, setResendingEnableTime] = useCookie<number>(
    'enable-sms-resending-time-on',
    new Date().getTime() + delay,
  );

  const setResendingEnableTimeCookie = () => {
    if (resendingEnableTimeCookie <= new Date().getTime() + delay) {
      setResendingEnableTime(new Date().getTime() + delay);
    }
  };
  return {
    resendingEnableTimeCookie,
    setResendingEnableTimeCookie,
  };
};

export default useResendingEnableTimeCookie;
