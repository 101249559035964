import { Header } from '@heathmont/moon-core';
import { ControlsChevronLeft, ControlsClose } from '@heathmont/moon-icons';
import rem from 'polished/lib/helpers/rem';
import useAgentData from '~/client/hooks/useAgentData';
import Translate from '~/client/shared/i18n/Translate';
import Stack from '~/client/shared/Stack';
import DesktopDialog from './DesktopDialog';
import MobileDialog from './MobileDialog';

type Props = {
  onClose: () => void;
  title?: string;
  onClickBack?: () => void;
};

const Dialog: FC<Props> = ({ children, onClose, title, onClickBack }) => {
  const { isMobile } = useAgentData();
  const backButton = onClickBack ? (
    <ControlsChevronLeft
      fontSize={rem(16)}
      onClick={onClickBack}
      data-testid="back-button"
    />
  ) : undefined;
  const header = (
    <Header
      closeButton={
        <ControlsClose
          fontSize={rem(16)}
          onClick={onClose}
          data-testid="close-button"
        />
      }
      isDivider
      backButton={backButton}
    >
      {title && <Translate id={title} />}
    </Header>
  );
  if (isMobile) {
    return (
      <MobileDialog onClose={onClose}>
        {header}
        <Stack
          padding={`${rem(16)} ${rem(24)} ${rem(24)}`}
          gridGap={rem(24)}
          data-testid="2FA-dialog"
        >
          {children}
        </Stack>
      </MobileDialog>
    );
  }
  return (
    <DesktopDialog onClose={onClose} header={header}>
      <Stack
        padding={`${rem(16)} ${rem(24)} ${rem(24)}`}
        gridGap={rem(24)}
        data-testid="2FA-dialog"
      >
        {children}
      </Stack>
    </DesktopDialog>
  );
};

export default Dialog;
