import { DialogOverlay } from '@heathmont/moon-components';
import styled from 'styled-components';
import MobileDialogContent from './MobileDialogContent';

type Props = {
  onClose: () => void;
};

const DialogOverlayOnTop = styled(DialogOverlay)`
  && {
    z-index: 3000000000;
  }
`;

const MobileDialog: FC<Props> = ({ onClose, children }) => (
  <DialogOverlayOnTop isOpen onClick={onClose}>
    <MobileDialogContent>{children}</MobileDialogContent>
  </DialogOverlayOnTop>
);

export default MobileDialog;
