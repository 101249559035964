import { useCallback } from 'react';
import useRedirectUrl from '~/client/components/Auth/hooks/useRedirectUrl';
import useSocialAuthConf from '~/client/features/SocialAuth/hooks/useSocialAuthConf';
import useSocialAuthType from '~/client/hooks/cookies/useSocialAuthType';
import useFeature from '~/client/hooks/feature/useFeature';
import useRouter from '~/client/hooks/router/useRouter';
import useAppContext from '~/shared/context/AppContext/useAppContext';
import getEnv from '~/shared/utils/getEnv';
import getCsrfToken from '../../../../csrf/getCsrfToken';

const useAuth0CallbackUri = () => {
  const { pathPrefix } = useAppContext();
  return `${global.location?.origin}${pathPrefix}/callback`;
};

const useAuth0ConnectionClicked = () => {
  const { pathname, asPath } = useRouter();
  const redirectPath = useRedirectUrl();
  const redirect = redirectPath === '' ? asPath : redirectPath;
  const redirectURL = useAuth0CallbackUri();
  const isAuth88CallbackEnabled = useFeature('isAuth88CallbackEnabled');
  const auth0RedirectUri = isAuth88CallbackEnabled
    ? getEnv('SOCIAL_AUTH_CALLBACK_URL') ?? 'https://auth88.io/auth-callback'
    : redirectURL;

  const [, setSocialAuthType] = useSocialAuthType();
  const { auth0Config } = useSocialAuthConf();

  const handleClick = useCallback(
    (connection: Connection) => {
      if (auth0Config) {
        const { domain, clientId } = auth0Config;
        const state = btoa(
          JSON.stringify({
            connection,
            redirect,
            csrf: getCsrfToken(),
            redirectURL,
          }),
        );
        const auth0Uri = `https://${domain}/authorize?client_id=${clientId}&connection=${connection}&redirect_uri=${auth0RedirectUri}&response_type=token&scope=openid%20profile%20email&auth0Client=eyJuYW1lIjoiYXV0aDAuanMiLCJ2ZXJzaW9uIjoiOS4xOS4wIn0%&state=${state}`;
        location.assign(auth0Uri);
      }
      sessionStorage.setItem('social_auth_path', pathname);
      setSocialAuthType(connection);
    },
    [
      auth0Config,
      pathname,
      redirect,
      redirectURL,
      auth0RedirectUri,
      setSocialAuthType,
    ],
  );
  return handleClick;
};

export default useAuth0ConnectionClicked;
