import { useState } from 'react';
import useLanguage from '~/client/hooks/useLanguage';
import useSite from '~/client/hooks/useSite';
import { CurrentUserQueryDocument } from '~/shared/graphql/user/CurrentUserQuery.generated';
import {
  LoginMutationMutationResult,
  useLoginMutation,
} from './LoginMutation.generated';
import useStepByStepAuthContext from '../../Authenticate/AuthSteps/context/hooks/useStepByStepAuthContext';
import useReCaptchaV3 from '../../Shared/utils/useReCaptchaV3';
import type FormValues from '../LoginBody/FormLogin/useFormLogin/FormValues';

type HandleLoginArgs = Omit<FormValues, 'global'>;

type Args = {
  captchaRequired: boolean;
};
const hasErrors = (
  data: LoginMutationMutationResult['data'],
  error?: unknown,
) => error || data?.userManagementLoginViaPassword?.errors?.length;

const useLogin = ({ captchaRequired }: Args) => {
  const { code: language } = useLanguage();
  const [loading, setLoading] = useState(false);
  const { site } = useSite();
  const [login, { error }] = useLoginMutation();
  const { getV3Token, captchaVersion } = useReCaptchaV3({});
  const { authClearanceId } = useStepByStepAuthContext();

  const handleLogin = async ({
    password,
    username,
    captcha,
    otp = '',
    uses2FA,
  }: HandleLoginArgs) => {
    setLoading(true);
    const isRecaptchaV3 = captchaVersion === 'v3' && !captchaRequired;
    const version = isRecaptchaV3 ? 'v3' : 'v2';
    const captchaToken = isRecaptchaV3 ? await getV3Token() : captcha;

    const loginResult = await login({
      variables: {
        site,
        language,
        password,
        username,
        otp: uses2FA ? otp : undefined,
        captcha: captchaToken,
        captchaVersion: version,
        domain: window.location.hostname,
        authClearanceId,
      },
      refetchQueries: [
        { query: CurrentUserQueryDocument, variables: { site } },
      ],
    });

    if (hasErrors(loginResult.data, error)) {
      setLoading(false);
    }
    return loginResult;
  };
  return { handleLogin, loading };
};

export default useLogin;
