const serialize = (
  obj: Record<string, unknown>,
  options = { extended: false },
) => {
  // serialize should not encodeURIComponent() params because it's called repeatedly and the URL just keeps growing
  const { extended } = options;
  return (
    obj &&
    Object.keys(obj)
      .sort()
      .map((key) => {
        const val = obj[key];

        if (val === undefined) {
          return '';
        }

        if (val === null) {
          return key;
        }

        if (extended) {
          if (Array.isArray(val)) {
            return val.map((item) => `${key}=${item}`).join('&');
          }
        }

        return `${key}=${val}`;
      })
      .filter((x) => x.length > 0)
      .join('&')
  );
};

export default serialize;
