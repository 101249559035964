import LocalStorage from '~/shared/utils/storage/LocalStorage';

const generateCsrfToken = () => Math.random().toString(10);

const getCsrfToken = (): string => {
  const key = 'socialAuthCsrf';
  if (!LocalStorage.getItem(key).value) {
    const csrfToken = generateCsrfToken();
    LocalStorage.setItem(key, { value: csrfToken });
  }
  return LocalStorage.getItem(key).value;
};

export default getCsrfToken;
