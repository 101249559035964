import { useMemo } from 'react';
import useSite from '~/client/hooks/useSite';
import { UserQueryDocument } from '~/client/shared/graphql/UserQuery.generated';
import { TournamentsCountQueryDocument } from '../../Tournaments/graphql/TournamentsCountQuery.generated';

const useOnAuthRefetchQueries = () => {
  const { site } = useSite();

  const refetchQueries = useMemo(
    () => [
      {
        query: UserQueryDocument,
        variables: {
          site,
        },
      },
      {
        query: TournamentsCountQueryDocument,
        variables: {
          site,
          onlyCurrent: true,
        },
      },
    ],
    [site],
  );
  return refetchQueries;
};

export default useOnAuthRefetchQueries;
